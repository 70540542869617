




































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import EditTeacherSubject from './Subjects/EditTeacherSubject.vue';
import { TeacherSubjectDto } from '@/models/planning/TeacherSubjectDto';

export default Vue.extend({
  name: 'teacher-subjects',
  components: { EditTeacherSubject },
  data: () => ({
    deleting: undefined as TeacherSubjectDto|undefined,
    isSidebarOpen: false,
  }),
  async beforeMount() {
    await this.loadTeacherSubjects(this.selectedTeacher.id);
  },
  computed: {
    ...mapGetters('teachers', ['teachers', 'selectedTeacher']),
    ...mapGetters('teacherSubjectsStore', ['teacherSubjectApiState', 'teacherSubjects']),
    isLoading() {
      return this.teacherSubjectApiState === APISTATE.LOADING;
    }
  },
  methods: {
    ...mapActions('teacherSubjectsStore', ['loadTeacherSubjects', 'prepareNewTeacherSubject', 'removeTeacherSubject']),
    ...mapActions('validationStore', ['clearErrors']),
    createSubject() {
      this.prepareNewTeacherSubject(this.selectedTeacher.id);
      this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      this.clearErrors();
    },
    async remove(controls) {
      try {
        await this.removeTeacherSubject({
          relTeacher: this.deleting.relTeacher,
          relSchoolSubject: this.deleting.relSchoolSubject,
          relEducationalDirection: this.deleting.relEducationalDirection
        });
        controls.close();
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af fagtilknytning',
          type: 'is-danger'
        });
      }

    }
  }
})

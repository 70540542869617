


















































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { TeacherSubjectDto } from '@/models/planning/TeacherSubjectDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { EducationalDirectionDto } from '@/models/planning/EducationalDirectionDto';

export default Vue.extend({
  name: 'edit-teachersubject',
  data: () => ({
    form: {} as TeacherSubjectDto,

  }),
  mounted() {
    this.assignCriteria([
      { key: 'relEducationalDirection', validation:() => !!this.form.relEducationalDirection, message: "Skal udfyldes" },
      { key: 'relSchoolSubject', validation:() => !!this.form.relSchoolSubject, message: "Skal udfyldes" }
    ]);

  },
  computed: {
    ...mapGetters("teachers", ["teachers"]),
    ...mapGetters('educationDirectionsStore', {educationDirections: 'educationDirections', educationDirectionsLoading: 'isLoading'} ),
    ...mapGetters('teacherSubjectsStore', ['teacherSubject', 'teacherSubjects', 'teacherSubjectApiState']),
    ...mapGetters('schoolSubjectsStore', ['schoolSubjects']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage']),
    educationalDirectionsForTeacherSubject() {
      return this.educationDirections('teachersubject');
    },
    formRelTeacherNavigation: {
      get() {
        return this.teachers.find(q => q.id === this.form.relTeacher);
      },
      set(value:TeacherDto) {
        this.$set(this.form, 'relTeacher', value.id);
      }
    },
    formRelEducationalDirection: {
      get() {
        return this.educationDirections('teachersubject').find(q => q.id === this.form.relEducationalDirection);
      },
      set(value:EducationalDirectionDto) {
        this.$set(this.form, 'relEducationalDirection', value.id);
      }
    },
    formRelSchoolSubject: {
      get() {
        return this.schoolSubjects.find(q => q.id === this.form.relSchoolSubject);
      },
      set(value:SchoolSubjectDto) {
        this.$set(this.form, 'relSchoolSubject', value.id);
      }
    },
    isLoading() {
      return this.teacherSubjectApiState === APISTATE.LOADING;
    }
  },
  methods: {
    ...mapActions('teacherSubjectsStore', ['createTeacherSubject']),
    ...mapActions('validationStore', ['assignCriteria', 'validate', 'clearErrors']),
    checkExisting() {
      const existing = this.teacherSubjects.find(q => 
        q.relTeacher === this.form.relTeacher 
        && q.relSchoolSubject === this.form.relSchoolSubject 
        && q.relEducationalDirection === this.form.relEducationalDirection);

      if (existing) {
        this.$buefy.toast.open({
          message: 'Den valgte fagtilknytning eksisterer allerede.',
          type: 'is-danger'
        });
        return true;
      }
      return false;
    },
    close() {
      if (this.isLoading) return;

      this.$emit("close");
    },
    async create() {
      try {
        if (await this.validate()) {
          if (this.checkExisting()) {
            return;
          }
          await this.createTeacherSubject(this.form);
          this.close();
        }
        else {
          this.$buefy.toast.open({
            message: 'Valideringsfejl - check værdierne i de markerede felter.',
            type: 'is-danger'
          });
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opret af fag.',
          type: 'is-danger'
        });
      }
    },

  },
  watch: {
    teacherSubject: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.form = {...newValue};
      }
    }
  }
})
